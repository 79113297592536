import { RandomSlotSettingsForm } from '@/components/slot_scheduler/RandomSlotSettingsForm';
import { useSlotProgramOptions } from '@/hooks/programming_controls/useSlotProgramOptions';
import { useChannelEditor } from '@/store/selectors';
import { ArrowBack } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from '@tanstack/react-router';
import { RandomSlotSchedule } from '@tunarr/types/api';
import { useToggle } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { filter, isNil, isUndefined } from 'lodash-es';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Breadcrumbs from '../../components/Breadcrumbs';
import PaddedPaper from '../../components/base/PaddedPaper';
import ChannelProgrammingList from '../../components/channel_config/ChannelProgrammingList';
import UnsavedNavigationAlert from '../../components/settings/UnsavedNavigationAlert';
import { RandomSlots } from '../../components/slot_scheduler/RandomSlots';
import { lineupItemAppearsInSchedule } from '../../helpers/slotSchedulerUtil';
import { useUpdateLineup } from '../../hooks/useUpdateLineup';
import { resetLineup } from '../../store/channelEditor/actions';

dayjs.extend(duration);

export type RandomSlotForm = Omit<
  RandomSlotSchedule,
  'timeZoneOffset' | 'type'
>;

const defaultRandomSlotSchedule: RandomSlotSchedule = {
  type: 'random',
  padStyle: 'slot',
  randomDistribution: 'uniform',
  flexPreference: 'distribute',
  maxDays: 365,
  padMs: 1,
  slots: [],
  timeZoneOffset: new Date().getTimezoneOffset(),
};

export default function RandomSlotEditorPage() {
  const {
    currentEntity: channel,
    programList: newLineup,
    schedule: loadedSchedule,
  } = useChannelEditor();

  const updateLineupMutation = useUpdateLineup({
    onSuccess(data) {
      reset(data.schedule ?? defaultRandomSlotSchedule, {
        keepDefaultValues: false,
        keepDirty: false,
      });
    },
  });

  const theme = useTheme();
  const smallViewport = useMediaQuery(theme.breakpoints.down('sm'));
  const programOptions = useSlotProgramOptions();
  const [isCalculatingSlots, toggleIsCalculatingSlots] = useToggle(false);

  const hasExistingTimeSlotSchedule =
    !isNil(loadedSchedule) && loadedSchedule.type === 'time';

  const randomSlotForm = useForm<RandomSlotForm>({
    defaultValues:
      !isUndefined(loadedSchedule) && loadedSchedule.type === 'random'
        ? loadedSchedule
        : defaultRandomSlotSchedule,
  });

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid, isDirty },
    reset,
  } = randomSlotForm;

  const resetLineupToSaved = useCallback(() => {
    resetLineup();
    reset();
  }, [reset]);

  const onSave = () => {
    const schedule: RandomSlotSchedule = {
      ...getValues(),
      timeZoneOffset: new Date().getTimezoneOffset(),
      type: 'random',
    };

    // Find programs that have active slots
    const filteredLineup = filter(newLineup, (item) =>
      lineupItemAppearsInSchedule(getValues('slots'), item),
    );

    updateLineupMutation.mutate({
      channelId: channel!.id,
      lineupRequest: {
        type: 'random',
        schedule,
        programs: filteredLineup,
      },
    });
  };

  if (isUndefined(channel)) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Breadcrumbs />
      <Stack gap={2} useFlexGap>
        <Typography variant="h4">
          Edit Random Slots (Channel {channel?.number})
        </Typography>
        {hasExistingTimeSlotSchedule && (
          <Alert severity="warning">
            This channel has an existing time slot schedule. A channel can only
            use one scheduling type at a time. Saving a schedule here will
            remove the existing time slot schedule.
          </Alert>
        )}
        <PaddedPaper>
          <Stack
            direction="row"
            gap={1}
            sx={{ display: 'flex', alignContent: 'center' }}
          >
            <Typography sx={{ flexGrow: 1, fontWeight: 600 }}>
              Random Slots
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <RandomSlots
            control={control}
            setValue={setValue}
            watch={watch}
            programOptions={programOptions}
          />
          <Divider sx={{ my: 2 }} />
          <FormProvider {...randomSlotForm}>
            <RandomSlotSettingsForm
              onCalculateStart={() => toggleIsCalculatingSlots(true)}
              onCalculateEnd={() => toggleIsCalculatingSlots(false)}
            />
          </FormProvider>
        </PaddedPaper>
        <PaddedPaper>
          <Typography sx={{ pb: 1 }}>Programming Preview</Typography>

          <Divider />
          <Box sx={{ minHeight: 400 }}>
            <ChannelProgrammingList
              type="selector"
              enableDnd={false}
              enableRowDelete={false}
              enableRowEdit={false}
              listEmptyMessage={
                isCalculatingSlots ? 'Calculating Slots...' : null
              }
              virtualListProps={{
                width: '100%',
                height: 400,
                itemSize: smallViewport ? 70 : 35,
                overscanCount: 5,
              }}
            />
          </Box>
        </PaddedPaper>
      </Stack>
      <UnsavedNavigationAlert isDirty={isDirty} />
      <Box sx={{ display: 'flex', justifyContent: 'end', pt: 1, columnGap: 1 }}>
        <Box flexGrow={1}>
          <Button
            variant="outlined"
            to=".."
            component={RouterLink}
            startIcon={<ArrowBack />}
            sx={{ justifyContent: 'flex-start' }}
          >
            Back to Programming
          </Button>
        </Box>
        {isDirty && (
          <Button variant="contained" onClick={() => resetLineupToSaved()}>
            Reset Options
          </Button>
        )}
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={() => onSave()}
        >
          Save
        </Button>
      </Box>
    </>
  );
}
